import StandardIcon from 'components/global/icons/StandardIcon'
import { SendQuicklinkButton } from 'components/pageType/auth/SendQuicklinkButton'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import Alert from '../../components/global/alert'
import { Button } from '../../components/global/button/Button'
import { FormError } from '../../components/global/form/FormError'
import { EmailInput } from '../../components/global/form/input'
import MainLayout from '../../components/global/layouts/MainLayout'
import TitleCard from '../../components/global/messages/TitleCard'
import {
  LoginFormEmailInput,
  LoginFormPasswordInput,
} from '../../components/pageType/auth/login-form-inputs'
import { useForgotPasswordForm } from '../../hooks/pageType/auth/useForgotPasswordForm'
import { useLoginForm } from '../../hooks/pageType/auth/useLoginForm'
import useAuthStateQuery from '../../hooks/useAuthStateQuery'
import useLoginRedirect from '../../hooks/useLoginRedirect'
import routes from '../../lib/routes'
import mainLayout_getStaticProps from '../../lib/serverOnly/pageTypes/global/mainLayout/mainLayout_getStaticProps'
import isValidEmail from '../../lib/utils/isValidEmail'
import { withStaleWhileRevalidate } from '../../lib/utils/staleWhileRevalidate'
import { trpc } from '../../lib/utils/trpc'

function SignInCard({
  onValidEmail,
  onForgotPasswordClick,
  defaultEmail,
}: {
  onValidEmail: (email: string) => void
  onForgotPasswordClick: () => void
  defaultEmail?: string | null
}) {
  const { form, error, loading, onSubmit } = useLoginForm({
    defaultEmail: defaultEmail ?? undefined,
  })

  return (
    <TitleCard title="I'm a Returning Customer">
      <p className="mb-6 text-xl">Shopped here before?</p>
      <form noValidate onSubmit={onSubmit}>
        <>
          {error && (
            <Alert
              success={false}
              message="Your email address or password is incorrect. Please try again. If you've forgotten your sign in details, just click the 'Forgot your password?' link below."
            />
          )}
          <div className="space-y-7">
            <LoginFormEmailInput form={form} onValidEmail={onValidEmail} />
            <LoginFormPasswordInput form={form} />
          </div>
          <div className="mb-7 w-full text-right">
            <button
              type="button"
              className="mt-2 font-semibold capitalize text-purple-800 underline"
              onClick={onForgotPasswordClick}
            >
              Forgot your password?
            </button>
          </div>

          <Button disabled={loading} variant="fill" loading={loading} stretched type="submit">
            Login
          </Button>
        </>
      </form>
    </TitleCard>
  )
}

function ForgotPasswordCard({
  onValidEmail,
  defaultEmail = '',
  onBackPress,
}: {
  onValidEmail: (email: string | null) => void
  defaultEmail?: string | null
  onBackPress: (email: string | null) => void
}) {
  const { form, loading, onSubmit, sent, error, sentEmail } = useForgotPasswordForm({
    defaultEmail,
  })

  const email = form.watch('email')

  useEffect(() => {
    onValidEmail(isValidEmail(email) ? email : null)
  }, [email, onValidEmail])

  return (
    <TitleCard title="I'm a Returning Customer">
      {sent ? (
        <>
          <p className="pb-4 text-xl">
            {`Password reset instructions have been sent to ${sentEmail}`}
          </p>
          <p className="text-xl">Please check your inbox</p>
        </>
      ) : (
        <>
          <h3 className="text-lg font-bold">Reset password</h3>
          <p className="mb-6 text-xl">
            {"Enter your email and we'll send you a link to create a new password"}
          </p>
          <form noValidate className="space-y-8" onSubmit={onSubmit}>
            <EmailInput
              {...form.register('email', {
                required: true,
                validate: (value) => isValidEmail(value) || 'Please enter a valid email address',
              })}
              disabled={loading}
              error={form.formState.errors.email}
              required
              id="email"
              label="Email"
              hideLabel
            />
            <div className="space-y-4">
              <FormError error={error?.message} />
              <Button className="block w-full" variant="fill" disabled={loading} type={'submit'}>
                {loading ? 'Sending...' : 'Submit'}
              </Button>
              <Button
                className="block w-full"
                onClick={() => {
                  onBackPress(email)
                }}
                disabled={loading}
                type={'button'}
              >
                Cancel
              </Button>
            </div>
          </form>
        </>
      )}
    </TitleCard>
  )
}

export default function Login(props) {
  const router = useRouter()
  const [validEmail, setValidEmail] = useState<string | null>()
  const { data: customer, isLoading } = useAuthStateQuery()
  const redirectToCart = useLoginRedirect()
  const customerLoggedIn = useMemo(() => !isLoading && customer, [customer, isLoading])
  const redirectSourceQuery = String(router.query.redirect_source || '')

  const hasCheckedForCustomer = useRef(false)

  // redirect to cart if logged in
  useEffect(() => {
    if (!hasCheckedForCustomer.current && !isLoading) {
      if (customerLoggedIn) {
        !redirectSourceQuery
          ? redirectToCart()
          : router.replace(decodeURIComponent(redirectSourceQuery))
      }

      // Avoid redirect on login - this should be left to the hook!
      hasCheckedForCustomer.current = true
    }
  }, [customerLoggedIn, redirectSourceQuery, redirectToCart, router, isLoading])

  const { isLoading: sendingQuickLink, mutate: sendQuickLink } =
    trpc.auth.sendQuickLink.useMutation()

  const [stage, setStage] = useState<'sign-in' | 'forgot-password'>('sign-in')

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <MainLayout staticProps={props.layoutStaticProps}>
        <div>
          <h1 className="my-8 text-center text-2xl font-semibold text-gray-800 md:my-7 md:text-4xl">
            Sign in or Register
          </h1>
        </div>

        <div className="grid w-full grid-cols-2 gap-x-8">
          <div className="text-coolGray-800 col-span-2 m-3 rounded-lg border bg-white p-8 md:col-span-1">
            {stage === 'sign-in' ? (
              <SignInCard
                onValidEmail={setValidEmail}
                onForgotPasswordClick={() => setStage('forgot-password')}
                defaultEmail={validEmail}
              />
            ) : (
              <ForgotPasswordCard
                onBackPress={(email) => {
                  setValidEmail(email)
                  setStage('sign-in')
                }}
                onValidEmail={setValidEmail}
                defaultEmail={validEmail}
              />
            )}
          </div>

          <div className="text-coolGray-800 col-span-2 m-3 rounded-lg border bg-white p-8 md:col-span-1">
            {validEmail ? (
              <TitleCard
                title={
                  <div className="flex flex-row items-center">
                    <div>Tired Of Typing Passwords?</div>
                    <div>
                      <StandardIcon icon="legacy/lightbulb" alt="failure" width={75} height={75} />
                    </div>
                  </div>
                }
              >
                <p className="mx-0 mb-6 mt-0 hidden p-0 text-gray-800 md:mx-auto md:my-0 md:block md:pr-36">
                  You can receive a quick link at <strong>{validEmail}</strong> that instantly logs
                  you into your account without entering a password.
                </p>
                <div className="mt-12">
                  <SendQuicklinkButton
                    sendingQuickLink={sendingQuickLink}
                    email={validEmail}
                    onClick={(email) => {
                      if (email) {
                        sendQuickLink({ email })
                      }
                    }}
                  />
                  <div className="mt-4">
                    <Link href={routes.createAccount()}>
                      <Button variant="outline">Create an Account</Button>
                    </Link>
                  </div>
                </div>
              </TitleCard>
            ) : (
              <TitleCard title="I'm a New Customer" showPartyBanner>
                <p className="mx-0 mb-6 mt-0 hidden p-0 text-gray-800 md:mx-auto md:my-0 md:block md:pr-36">
                  Creating an account with us is easy, fast, and free. You&apos;ll be able to ...
                </p>
                <ul className="mb-6 ml-6 mr-0 mt-4 hidden list-outside list-disc p-0 lowercase text-gray-800 md:mb-8	md:ml-10 md:mr-0 md:block md:lowercase">
                  <li>Manage your orders</li>
                  <li>Save payment info</li>
                  <li>Track a package</li>
                </ul>

                <Link href={routes.createAccount()}>
                  <Button variant="outline">Create an Account</Button>
                </Link>
              </TitleCard>
            )}
          </div>
        </div>
      </MainLayout>
    </>
  )
}

export const getServerSideProps = withStaleWhileRevalidate(async function () {
  const layoutStaticProps = await mainLayout_getStaticProps()

  return {
    props: { layoutStaticProps },
  }
})
