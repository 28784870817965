import { useForm } from 'react-hook-form'
import useLoginMutation from '../../useLoginMutation'

type FormValues = { email: string; password: string }

export function useLoginForm({
  onSuccess,
  defaultEmail,
}: {
  onSuccess?: () => void
  defaultEmail?: string
}) {
  const {
    mutate: loginMutation,
    error,
    isLoading,
  } = useLoginMutation({
    onSuccess,
    onIncorrectPassword: () => {
      form.setError('password', {
        message: 'Incorrect password. Password will contain at least 7 characters and a number.',
      })
    },
    onIncorrectEmail: () => {
      form.setError('email', {
        message: 'Please check the entered email. We were unable to find it in our system.',
      })
    },
  })

  const form = useForm<FormValues>({
    defaultValues: { email: defaultEmail },
    mode: 'onBlur',
  })
  const onSubmit = form.handleSubmit((values) => loginMutation(values))

  return {
    form,
    onSubmit,
    error,
    loading: isLoading,
  }
}

export type UseLoginForm = ReturnType<typeof useLoginForm>['form']
