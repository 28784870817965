import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { trpc } from '../../../lib/utils/trpc'

export function useForgotPasswordForm({ defaultEmail = '' }: { defaultEmail?: string | null }) {
  const [sent, setSent] = useState(false)
  const [sentEmail, setSentEmail] = useState<string | null>(null)

  const form = useForm<{ email: string }>({
    defaultValues: {
      email: defaultEmail ?? '',
    },
    mode: 'onBlur',
  })

  const {
    mutate: sendEmail,
    isLoading: loading,
    error,
  } = trpc.auth.sendResetPasswordEmail.useMutation({
    onSuccess: () => {
      setSent(true)
    },
  })

  const onSubmit = form.handleSubmit(async (values) => {
    await sendEmail({ email: values.email })
    setSentEmail(values.email)
  })

  return { form, onSubmit, loading, sent, sentEmail, error }
}
