import { useEffect } from 'react'
import type { UseLoginForm } from '../../../hooks/pageType/auth/useLoginForm'
import ANALYTICS_ACTIONS from '../../../lib/constants/ANALYTICS_ACTIONS'
import isValidEmail from '../../../lib/utils/isValidEmail'
import { EmailInput, PasswordInput } from '../../global/form/input'

const reminderMessage = 'Password will contain at least 7 characters and a number.'

export function LoginFormPasswordInput({
  form,
  disabled,
  className,
  placeholder,
}: {
  form: UseLoginForm
  disabled?: boolean
  className?: string
  placeholder?: string
}) {
  if (
    form.formState.errors.password?.message &&
    !form.formState.errors.password.message.includes(reminderMessage)
  ) {
    form.formState.errors.password.message += `. ${reminderMessage}`
  }
  return (
    <PasswordInput
      id="password"
      placeholder={placeholder}
      label="Password"
      {...form.register('password', {
        required: true,
      })}
      className={className}
      disabled={disabled}
      required
      error={form.formState.errors.password}
      analyticsErrorAction={ANALYTICS_ACTIONS.loginErrorMessage}
    />
  )
}

export function LoginFormEmailInput({
  form,
  className,
  onValidEmail,
  disabled,
  placeholder,
  error,
}: {
  form: UseLoginForm
  className?: string
  onValidEmail?: (email: string | null) => void
  disabled?: boolean
  placeholder?: string
  error?: string
}) {
  const email = form.watch('email')

  useEffect(() => {
    const blah = isValidEmail(email) ? email : null
    onValidEmail?.(blah)
  }, [email, onValidEmail])

  return (
    <EmailInput
      id="email"
      className={className}
      placeholder={placeholder}
      label="Email"
      {...form.register('email', {
        required: true,
        validate: (value) => (isValidEmail(value) ? true : 'Please enter a valid email address'),
      })}
      error={form.formState.errors.email || error}
      disabled={disabled}
      required
      analyticsErrorAction={ANALYTICS_ACTIONS.loginErrorMessage}
    />
  )
}
