import analytics from '../../../lib/constants/ANALYTICS_ACTIONS'
import { Button } from '../../global/button/Button'

export function SendQuicklinkButton({
  sendingQuickLink,
  email,
  onClick,
  className,
}: {
  sendingQuickLink: boolean
  email: string | null
  onClick: (email: string | null) => void
  className?: string
}) {
  return (
    <Button
      className={className}
      variant="fill"
      type="button"
      disabled={sendingQuickLink}
      loading={sendingQuickLink}
      data-cw-track={analytics.quickLinkBtnClick}
      onClick={() => {
        onClick(email)
      }}
    >
      {sendingQuickLink ? 'Loading...' : 'Email me a QuickLink'}
    </Button>
  )
}
