import { useRouter } from 'next/router'
import { useUI } from '../components/global/ui-context'
import useGTMUserEvents from '../lib/clientOnly/analytics/gtm/useGTMUserEvents'
import { updateCustomerSegments } from '../lib/clientOnly/api/updateCustomerSegments'
import { trpc } from '../lib/utils/trpc'

export default function useLoginMutation({
  onSuccess,
  onIncorrectPassword,
  onIncorrectEmail,
  replacePath,
}: {
  onSuccess?: () => void
  replacePath?: string
  onIncorrectEmail?: () => void
  onIncorrectPassword?: () => void
}) {
  const { closeAuthModal } = useUI()
  const { login: sendLoginGTMEvent } = useGTMUserEvents()

  const utils = trpc.useUtils()

  const router = useRouter()

  const { error, ...rest } = trpc.auth.login.useMutation({
    onSuccess: async (data) => {
      if (data.success) {
        const customer = data.customer
        sendLoginGTMEvent({ method: 'password', customer })
        updateCustomerSegments(true) // TODO: This is crazy - this should be all backend e.g. via a webhook
        closeAuthModal()

        onSuccess?.()

        // Reset all queries to take into account new auth state
        utils.invalidate()

        // Force refetch customer & cart before we redirect
        await Promise.all([utils.customer.getCustomer.refetch(), utils.cart.getCart.refetch()])

        if (replacePath) {
          window.location.replace(replacePath)
        } else if (data.redirect) {
          await router.replace(data.redirect)
        }
      } else {
        if (data.reason === 'email') {
          onIncorrectEmail?.()
        } else if (data.reason === 'password') {
          onIncorrectPassword?.()
        }
      }
    },
  })

  return {
    ...rest,
    error: error?.data?.code === 'UNAUTHORIZED' ? null : error,
  }
}
